import React from 'react';
import { IFormState } from '../Capsule';
import ServiceAccordion from '../../ServiceAccordion';
import { QuickPriceCalculatorTypeWidget } from '../../../semshared/pricelist/quickprice_widget';

interface ServiceSectionProps {
  setFormState: (formState: any) => void;
  formState: IFormState;
  serviceTypes: any[];
  quickPriceCalculator: QuickPriceCalculatorTypeWidget | null;
}

const ServiceSection = ({ setFormState, formState, serviceTypes, quickPriceCalculator }: ServiceSectionProps) => {
  return (
    <>
      {serviceTypes && (
        <ServiceAccordion
          onChange={value => {
            setFormState({
              ...formState,
              service: value,
            });
          }}
          defaultValue={formState.service}
          values={serviceTypes.map((serviceType: any) => ({
            objValue: serviceType,
            value: serviceType.sku,
            content: serviceType.details,
          }))}
        />
      )}
    </>
  );
};

export default ServiceSection;
